import React from 'react';

import Image from '../assets/avatar.svg'

import {FaGithub, FaLinkedin} from 'react-icons/fa'

import {TypeAnimation} from 'react-type-animation'

import {motion} from 'framer-motion'

import {fadeIn} from '../variants';
import { Link } from 'react-scroll';

const Banner = () => {
  return <section className='min-h-[85vh] lg:min-h-[78vh] flex items-center' id="home">
    <div className="container mx-auto">
      <div className='flex flex-col gap-y-8 lg:flex-row lg:items-center lg:gap-x-12'>
        <div className='flex-1 text-center font-secondary lg:text-left'>
          <motion.h1 
          variants={fadeIn('up', 0.3)} 
          initial="hidden" 
          whileInView={'show'} 
          viewport={{once: false, amount: 0.7}} 
          className='text-[55px] font-bold leading-[0.8] lg:text-[110px]'>
            AURELIEN <span>CARENJOT</span>
          </motion.h1>
          <motion.div
          variants={fadeIn('up', 0.4)} 
          initial="hidden" 
          whileInView={'show'} 
          viewport={{once: false, amount: 0.7}} 
          className='mb-6 text-[36px] lg:text-[60px] font-secondary font-semibold uppercase leading-[1]'>
            <span className='text-white mr-4'>Un</span>
            <TypeAnimation sequence={[
              'Développeur',
              2000,
              'Créateur',
              2000,
              'Modeleur',
              2000
            ]} 
            speed={50}
            className='text-sky-400'
            wrapper='span'
            repeat={Infinity}/>
            
          </motion.div>
          <motion.p 
          variants={fadeIn('up', 0.5)} 
          initial="hidden" 
          whileInView={'show'} 
          viewport={{once: false, amount: 0.7}} 
          className='mb-8 max-w-lg mx-auto lg:mx-0'>
            Face au changement, ma capacité d’adaptation naturelle et ma flexibilité m'ont beaucoup aidé.
          </motion.p> 
          <motion.div
          variants={fadeIn('up', 0.6)} 
          initial="hidden" 
          whileInView={'show'} 
          viewport={{once: false, amount: 0.7}} 
          className='flex max-w-max gap-x-8 items-center mb-12 mx-auto lg:mx-0'>
            <Link to='contact' smooth={true} spy={true}><button className='btn btn-lg'>Contactez moi</button></Link>
          </motion.div>
          <motion.div 
          variants={fadeIn('up', 0.7)} 
          initial="hidden" 
          whileInView={'show'} 
          viewport={{once: false, amount: 0.7}} 
          className='flex text-[20px] gap-x-6 max-w-max mx-auto lg:mx-0'>
            <a href='https://github.com/Carenjot'>
              <FaGithub/>
            </a>
            <a href='https://www.linkedin.com/in/aurélien-carenjot/'>
              <FaLinkedin/>
            </a>
          </motion.div>
        </div>
        <motion.div 
        variants={fadeIn('down', 0.5)} 
        initial="hidden" 
        whileInView={'show'} 
        className='hidden lg:flex flex-1 max-w-[500px] lg:max-w-[482px]'>
          <img src={Image} alt=''/>
        </motion.div>
      </div>
    </div>
  </section>;
};

export default Banner;
